import "./modules/SubscriptionSelector";
import ProductCardVariantSelector from "./modules/ProductCardVariantSelector";
import "./modules/KlaviyoBIS";
import productPriceWatcher from "./modules/SubscriptionPriceWatcher";
import productPriceUpdate from "./modules/ProductPriceUpdate";

new ProductCardVariantSelector;

productPriceUpdate();
productPriceWatcher();

// document.addEventListener("updatePrice_Dispatch", (e) => console.log(e.detail));
// document.addEventListener("handleRadioChange_Dispatch", (e) => console.log(e.detail));